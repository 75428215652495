<template>
  <menubar />
  <main-container>
    <!-- TOP -->
    <template v-slot:header>
      <button class="a-main-btn" @click="settings.addModal = true">
        Agregar
      </button>
      <div class="a-search-container">
        <InputText placeholder="Buscar" v-model="filters['global'].value" />
      </div>
    </template>

    <DataTable
      :value="users"
      :paginator="true"
      :rows="8"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
      :filters="filters"
      :globalFilterFields="[
        'username',
        'first_name',
        'last_name',
        'userType.name',
        'email',
      ]"
    >
      <!-- Fields defined in data section -->
      <Column
        v-for="col of fields"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        sortable
      />

      <!-- Action buttons -->
      <Column headerStyle="width: 9rem">
        <template #body="slotProps">
          <div class="a-container-tab-btns">
            <button
              class="a-icon-tab-btn a-detail-btn"
              @click="detailUser(slotProps.data)"
            >
              <img :src="require('@/assets/icons/eye-white.svg')" />
            </button>
            <button
              class="a-icon-tab-btn a-delete-btn"
              @click="deleteUser(slotProps.data)"
              :disabled="!slotProps.data.is_active"
            >
              <img :src="require('@/assets/icons/cross.svg')" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </main-container>
  <Dialog
    header="Añadir usuario"
    v-model:visible="settings.addModal"
    v-if="settings.addModal"
    :modal="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '80vw' }"
  >
    <new-user @onSuccess="userAdded" @onExit="settings.addModal = false" />
  </Dialog>

  <Dialog
    v-model:visible="settings.detailModal"
    v-if="settings.detailModal"
    :modal="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h3>
        Usuario <span class="a-id-badge">{{ settings.user.id }}</span>
      </h3>
    </template>
    <detail-user :item="settings.user" :isEdit="false" />
  </Dialog>

  <ConfirmDialog />

  <Toast />
</template>

<script>
import Menubar from "@/components/navigation/Top.vue";
import MainContainer from "@/components/secure/MainContainer.vue";
import { FilterMatchMode } from "primevue/api";
import NewUser from "@/components/auth/NewUser.vue";
import DetailUser from "@/components/auth/DetailUser.vue";
export default {
  components: {
    Menubar,
    MainContainer,
    NewUser,
    DetailUser,
  },
  data() {
    return {
      users: [],
      fields: [
        {
          field: "username",
          header: "Usuario",
        },
        {
          field: "userType.name",
          header: "Tipo",
        },
        {
          field: "email",
          header: "Correo electrónico",
        },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      settings: {
        addModal: false,
        detailModal: false,
        user: null,
      },
    };
  },
  methods: {
    getUsers() {
      this.$store
        .dispatch("users/GET_USERS")
        .then((response) => {
          this.users = response;
        })
        .catch((error) => {
          if (error == "token") {
            this.getUsers();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error,
              life: 3000,
            });
          }
        });
    },
    userAdded() {
      this.settings.addModal = false;
      this.getUsers();
      this.$toast.add({
        severity: "success",
        detail: "Se ha añadido un nuevo usuario",
        life: 3000,
      });
    },
    detailUser(item) {
      this.settings.user = item;
      this.settings.detailModal = true;
    },
    deleteUser(item) {
      let message = `¿Estás seguro de eliminar el usuario ${item.username}?`;
      this.$confirm.require({
        message: message,
        header: "Eliminar usuario",
        acceptLabel: "Aceptar",
        acceptClass: "p-button-danger",
        rejectLabel: "Cancelar",
        accept: () => {
          //callback to execute when user confirms the action
          this.$store
            .dispatch("users/DELETE_USER", item)
            .then(() => this.getUsers)
            .catch((error) => {
              if (error == "token") {
                this.deleteUser(item);
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Ha ocurrido un error",
                  detail: error,
                  life: 3000,
                });
              }
            });
        },
      });
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style>
.a-content {
  background: white;
}
</style>
