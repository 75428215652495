<template>
  <div id="a-new-user-content">
    <div>
      <InputText type="text" v-model="user.first_name" placeholder="Nombre" />
      <InputText type="text" v-model="user.last_name" placeholder="Apellido" />
      <InputText
        type="text"
        v-model="user.email"
        placeholder="Correo electrónico"
      />
      <Dropdown
        v-model="user.userType"
        :options="usertypes"
        optionLabel="name"
        placeholder="Tipo de usuario"
        :showClear="true"
      />
    </div>
    <div id="a-user-card" @click="copyInfo()">
      <div id="a-user-icon">
        <img :src="require('@/assets/images/auth/user.svg')" />
      </div>
      <div id="a-user-info">
        <div>
          <span><strong>Usuario</strong></span>
          <br />
          <span v-if="genUsername == '.'">xxxxxxxxxx</span>
          <span v-else>{{ genUsername }}</span>
        </div>

        <div>
          <span><strong>Contraseña</strong></span>
          <br />
          <span>{{ user.password }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="a-buttons">
    <Button
      label="Cancelar"
      class="p-button-raised p-button-danger p-button-text"
      @click="cancel"
    />
    <button
      :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
      :disabled="isLoading || !isValid"
      @click="newUser"
    >
      Aceptar
    </button>
  </div>
  <Toast />
</template>

<script>
export default {
  name: "NewUser",
  data() {
    return {
      user: {
        username: "",
        email: "",
        password: "123AB$",
        first_name: "",
        secondName: "",
        last_name: "",
        secondSurname: "",
        userType: null,
        is_active: true,
      },
      usertypes: [],
      isLoading: false,
    };
  },
  emits: ["onSuccess", "onExit"],
  methods: {
    cancel() {
      this.$emit("onExit");
    },
    newUser() {
      this.isLoading = true;
      this.user.userType = this.user.userType.id;
      this.user.username = this.genUsername;

      var form_data = new FormData();
      for (var key in this.user) {
        form_data.append(key, this.user[key]);
      }

      this.$store
        .dispatch("users/CREATE_USER", form_data)
        .then(() => {
          this.$emit("onSuccess");
        })
        .catch((error) => {
          if (error == "token") {
            this.newUser();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error,
              life: 3000,
            });
          }
        });
    },
    async copyInfo() {
      if (this.genUsername !== ".") {
        await navigator.clipboard.writeText(
          `Usuario: ${this.genUsername}\nContraseña: ${this.user.password}`
        );
        alert(
          `Se copiaron las credenciales\nUsuario: ${this.genUsername}\nConstraseña: ${this.user.password}`
        );
      } else {
        alert("Aún no se han generado las credenciales.");
      }
    },
    getUserTypes() {
      this.$store
        .dispatch("users/GET_USER_TYPES")
        .then((response) => {
          this.usertypes = response;
        })
        .catch((error) => {
          if (error == "token") {
            this.getUserTypes();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error,
              life: 3000,
            });
          }
        });
    },
  },
  mounted() {
    this.getUserTypes();
  },
  computed: {
    isValid() {
      return (
        this.user.email != "" &&
        this.user.first_name != "" &&
        this.user.last_name != "" &&
        this.user.userType != null
      );
    },
    genUsername() {
      let names = this.user.first_name.split(" ");
      let lastnames = this.user.last_name.split(" ");
      if (lastnames.length == 2) {
        return (
          names[0] +
          "." +
          lastnames[0] +
          lastnames[1].charAt(0)
        ).toLowerCase();
      }
      return (names[0] + "." + lastnames[0]).toLowerCase();
    },
  },
};
</script>

<style scoped>
#a-new-user-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}
#a-user-card {
  /* cursor: url("@/assets/icons/pencil.svg"), auto; */
  background: var(--color-1);
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: 18px 30px;
}

#a-user-card #a-user-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
#a-user-card:hover {
  cursor: copy;
}
#a-user-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
